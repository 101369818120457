import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import { Icon } from '@iconify/react';
import CustomModal from '../modals/CustomModal';
import Pagination from '../common/Pagination';
import AddNewRoleForm from '../forms/AddNewRoleForm';
import { getAllRolesApi } from '../../rest/RoleApi';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { isDataFetchingState } from '../../recoil/store';
import { getAllFeaturesApi } from '../../rest/FeatureApi';
import { CustomButton } from '../common/Form';
import { updateUserRoleByIdApi } from '../../rest/UserApi';
import showToast from '../../utils/toast';
import { SUCCESS_VARIANT } from '../../constants/constant';
import NoDataFound from '../common/NoDataFound';
import { customBlue } from '../../constants/color';
import Loader from '../common/Loader';

const GlobalFilter = ({ globalFilter, setGlobalFilter, setIsModalOpen, showAddNewButton, fetchFeatures }) => {
    return (
        <div className={`flex ${showAddNewButton ? 'justify-between' : 'justify-end'} px-6 items-center h-[4.5rem] bg-gray-50 rounded-lg`}>

            <h3 className='text-lg font-bold font-quicksand'>
                Assign Role
            </h3>

            <div className='flex'>
                <div className='flex items-center h-8 mr-4'>
                    <input
                        value={globalFilter || ''}
                        onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                        className="px-4 py-1 h-8 min-w-56 border border-gray-400 rounded outline-none text-sm font-quicksand"
                        placeholder="Search..."
                    />
                    <button
                        className="px-3 h-full ml-2 bg-custom-blue text-white rounded disabled:opacity-50 text-sm font-medium font-quicksand"
                    >
                        <Icon
                            icon='mingcute:search-line'
                            color='white'
                            fontSize={20}
                        />
                    </button>
                </div>

                {
                    showAddNewButton && (
                        <div className='h-8'>
                            <button
                                onClick={() => {
                                    setIsModalOpen(true);
                                    fetchFeatures();
                                }}
                                className="px-3 h-full ml-2 bg-custom-blue text-white rounded disabled:opacity-50 text-sm font-medium font-quicksand"
                            >
                                Add new role
                            </button>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

const AssignRoleDataTable = ({ showAddNewButton = true, setUserData, userDetail = {} }) => {
    const userRoles = userDetail?.roles;
    const [data, setData] = useState([]);
    const [isDataFetching, setIsDataFetching] = useRecoilState(isDataFetchingState);
    const [roleNameList, setRoleNameList] = useState(userRoles);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [allFeatures, setAllFeatures] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const columns = useMemo(() => [
        {
            Header: '',
            accessor: 'select',
            Cell: memo(({ row }) => {
                return (
                    <div
                        className='flex items-center'>
                        <input
                            type='checkbox'
                            checked={roleNameList.includes(row?.cells[1]?.value)}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleRowClick(row);
                            }}
                            onChange={() => { }}
                            className={`h-4 w-4 mr-2 cursor-pointer checked:bg-sky-500 border-2 rounded-md border-gray-400 checked:border-sky-500`}
                        />
                    </div>
                )
            })
        },
        {
            Header: 'Role',
            accessor: 'name',
        },
        {
            Header: 'Description',
            accessor: 'description',
        },
    ], [roleNameList, setRoleNameList]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setPageSize,
        state,
        setGlobalFilter,
        preGlobalFilteredRows,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 5 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const { globalFilter, pageSize } = state;

    // Fetch roles data from the API
    const fetchData = async () => {
        setIsDataFetching(true);
        const result = await getAllRolesApi(pageNumber, pageSize);
        if (result?.status === 200) {
            setData(result.data.results);
            setTotalPages(result.data.totalPage);
        }
        setIsDataFetching(false);
    };

    useEffect(() => {
        fetchData();
    }, [pageNumber, pageSize]);

    // Fetch features data from the API
    const fetchFeatures = async () => {
        setIsDataFetching(true);
        const result = await getAllFeaturesApi();
        if (result?.status === 200) {
            setAllFeatures(result.data.results);
        }
        setIsDataFetching(false);
    };

    const updateUserRole = async () => {
        setIsLoading(true);
        const result = await updateUserRoleByIdApi(userDetail?.id, { roles: roleNameList });
        if (result?.status === 200) {
            setUserData((prevData) => {
                const updatedUserData = prevData.map((item) => {
                    if (item.id === userDetail?.id) {
                        return { ...item, roles: roleNameList };
                    }
                    return item;
                });
                return updatedUserData;
            });
            showToast('Role updated', SUCCESS_VARIANT);
        }
        setIsLoading(false);
    };

    const handleRowClick = (row) => {
        const selectedRoleName = row?.cells[1]?.value;
        setRoleNameList((prevRoleNameList) => {
            if (prevRoleNameList.includes(selectedRoleName)) {
                return prevRoleNameList.filter((role) => role !== selectedRoleName);
            } else {
                return [...prevRoleNameList, selectedRoleName];
            }
        });
    };

    return (
        <div className="p-8 bg-white rounded-xl">
            {isModalOpen && (
                <CustomModal
                    width='w-[28rem]'
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                >
                    <AddNewRoleForm
                        allFeatures={allFeatures}
                        setData={setData}
                    />
                </CustomModal>
            )}

            <GlobalFilter
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                preGlobalFilteredRows={preGlobalFilteredRows}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                showAddNewButton={showAddNewButton}
                fetchFeatures={fetchFeatures}
            />

            <div className="overflow-x-auto mt-4">
                <table {...getTableProps()} className="min-w-full bg-white">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    column.Header ? (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            className="px-4 py-2 bg-white text-left text-sm font-bold text-gray-500 tracking-wider border-b border-gray-300 cursor-pointer font-quicksand "
                                        >
                                            <div className='flex items-center'>
                                                {column.render('Header')}
                                                <span className="ml-2">
                                                    {column.isSorted ? (
                                                        column.isSortedDesc ? (
                                                            <Icon
                                                                icon='eva:arrow-down-fill'
                                                                color='gray'
                                                                fontSize={14}
                                                            />
                                                        ) : (
                                                            <Icon
                                                                icon='eva:arrow-up-fill'
                                                                color='gray'
                                                                fontSize={14}
                                                            />
                                                        )
                                                    ) : (
                                                        <Icon
                                                            icon='flowbite:sort-outline'
                                                            color='gray'
                                                            fontSize={14}
                                                        />
                                                    )}
                                                </span>
                                            </div>
                                        </th>
                                    ) : (
                                        <th className="px-4 py-2 bg-white text-left text-sm font-bold text-gray-500 tracking-wider border-b border-gray-300 cursor-pointer font-quicksand "></th>
                                    )
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            isDataFetching ? (
                                <tr>
                                    <td
                                        colSpan={columns.length} // Spans all columns
                                        className="px-4 py-10 text-sm font-quicksand font-semibold text-gray-700 border-b border-gray-300 text-center"
                                    >
                                        <Loader
                                            color={customBlue}
                                            size="2.5rem"
                                            borderWidth='3px'
                                        />
                                    </td>
                                </tr>
                            ) :
                                page.length > 0 ? (
                                    page.map((row, rowIndex) => {
                                        prepareRow(row);
                                        return (
                                            <tr
                                                {...row.getRowProps()}
                                                className='hover:bg-gray-50 cursor-pointer'
                                                onClick={() => handleRowClick(row)}
                                            >
                                                {row.cells.map((cell) => (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        className="px-4 py-4 text-sm font-quicksand font-semibold text-gray-700 border-b border-gray-300"
                                                    >
                                                        {cell.column.id === 'description' && !cell.value ? (
                                                            '...'
                                                        ) : (
                                                            cell.render('Cell')
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                    })) : (
                                    <tr>
                                        <td
                                            colSpan={columns.length}
                                            className='border-b border-gray-300'
                                        >
                                            <NoDataFound
                                                message={'No Role Found'}
                                            />
                                        </td>
                                    </tr>
                                )}
                    </tbody>
                </table>
            </div>

            {/* Pagination Controls */}
            <div className="mt-6">
                <Pagination
                    totalPages={totalPages}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                />
            </div>

            <div className='mt-8'>
                <CustomButton
                    text='Save'
                    onClick={updateUserRole}
                    isLoading={isLoading}
                    width='8rem'
                    height='2.4rem'
                />
            </div>

        </div>
    );
};

export default AssignRoleDataTable;
