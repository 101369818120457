import React, { useState } from 'react'
import AddFactDetailForm from '../forms/AddFactDetailForm';
import AddFactQuestionForm from '../forms/AddFactQuestionForm';
import { addNewFactApi, updateCoverApi } from '../../rest/FactApi';
import showToast from '../../utils/toast';
import { ERROR_VARIANT, SUCCESS_VARIANT } from '../../constants/constant';

export default function AddNewFact({ setData, setIsModalOpen, setFactsCount }) {
    const [showFactDetail, setShowFactDetail] = useState(true);
    const [factDetails, setFactDetails] = useState({});
    const [factQuestions, setFactQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // <--------- Update Fact Row --------->
    async function updateFactRow(newFact) {
        setData(prevData => [...prevData, newFact]);
    }

    // <--------- Add Fact Cover --------->
    async function addFactCover(factId, body) {
        const result = await updateCoverApi(factId, body);
        if (result?.status === 200) {
            showToast('Fact added', SUCCESS_VARIANT);
            setIsModalOpen(false);
        }
        else
            showToast('No image uploaded', ERROR_VARIANT);
    }

    // <--------- Form submit function --------->
    async function handleFactSubmission(questions) {
        const data = { ...factDetails, questions };
        const imageFile = data.imageFile;
        delete data.imageFile;
        const formData = new FormData();
        formData.append('cover', imageFile);

        setIsLoading(true);
        const result = await addNewFactApi(data);
        if (result?.status === 201) {
            await addFactCover(result.data.id, formData);
            await updateFactRow({ ...result.data, categories: data?.categories, thumbnailUrl: `v1/facts/images/thumbnail/${result.data.id}.jpg` });
            setFactsCount((allFactsCount) =>
                allFactsCount.map((item) => {
                    if (item.status === 'IN_REVIEW') {
                        return { ...item, count: item.count + 1 };
                    }
                    if (item.status === 'TOTAL') {
                        return { ...item, count: item.count + 1 };
                    }
                    return item;
                })
            );
        }
        else if (result?.status === 400)
            showToast(result?.data?.message, ERROR_VARIANT);
        else
            showToast('Something went wrong', ERROR_VARIANT);
        setIsLoading(false);
    }

    return (
        <>
            <div style={{ display: showFactDetail ? 'block' : 'none' }}>
                <AddFactDetailForm
                    setFactDetails={setFactDetails}
                    setShowFactDetail={setShowFactDetail}
                />
            </div>

            <div style={{ display: !showFactDetail ? 'block' : 'none' }}>
                <AddFactQuestionForm
                    setShowFactDetail={setShowFactDetail}
                    setFactQuestions={setFactQuestions}
                    isLoading={isLoading}
                    handleFactSubmission={handleFactSubmission}
                />
            </div>
        </>
    )
}
