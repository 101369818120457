import React from 'react'
import CategoryDataTable from '../components/dataTables/CategoryDataTable'

export default function CategoryPage() {
    return (
        <div className='mx-8'>
            <CategoryDataTable />
        </div>
    )
}
