import React, { useState } from 'react'
import { CustomButton, Label, TextArea } from '../common/Form'
import { addNewFactApi } from '../../rest/FactApi';
import showToast from '../../utils/toast';
import { ERROR_VARIANT, SUCCESS_VARIANT } from '../../constants/constant';
import { useForm } from 'react-hook-form';

export default function AddFactUsingJSON({ setData, setFactsCount, setIsModalOpen }) {
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();

    // <--------- Update Fact Row --------->
    async function updateFactRow(newFact) {
        setData(prevData => [...prevData, newFact]);
    }

    // <--------- Form submit function --------->
    async function onSubmit(data) {
        setIsLoading(true);
        const result = await addNewFactApi(JSON.parse(data?.json));
        if (result?.status === 201) {
            await updateFactRow({ ...result.data, categories: data?.json?.categories });
            setFactsCount((allFactsCount) =>
                allFactsCount.map((item) => {
                    if (item.status === 'IN_REVIEW') {
                        return { ...item, count: item.count + 1 };
                    }
                    if (item.status === 'TOTAL') {
                        return { ...item, count: item.count + 1 };
                    }
                    return item;
                })
            );
            showToast('Fact added', SUCCESS_VARIANT);
            setIsModalOpen(false);
        }
        else if (result?.status === 400 || result?.status === 404 || result?.status === 415 || result?.status === 500)
            showToast(result?.data?.message, ERROR_VARIANT);
        else
            showToast('Something went wrong', ERROR_VARIANT);
        setIsLoading(false);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='p-6'>

            <div className="text-black text-center font-quicksand font-bold text-xl">
                Add New Fact (JSON Format)
            </div>

            <div className='mt-8'>
                <Label text='Json' />
                <TextArea
                    name='json'
                    placeholder='Enter JSON'
                    pattern={{
                        required: "JSON is mandatory*",
                        validate: (value) => {
                            try {
                                JSON.parse(value);
                                return true;
                            } catch {
                                return "Invalid JSON format*";
                            }
                        },
                    }}
                    register={register}
                    errors={errors}
                    rows={25}
                />
            </div>

            <div className='mt-6'>
                <CustomButton
                    text='Add'
                    width='6rem'
                    isLoading={isLoading}
                />
            </div>
        </form>
    )
}
