import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { CustomButton, CustomDropDown, InputField, Label, TextArea } from '../common/Form';
import AddTags from '../fact/AddTags';
import useMediaPicker from '../../hooks/useMediaPicker';
import { Icon } from '@iconify/react/dist/iconify.js';
import { ReactCrop } from 'react-image-crop';
import { FACT_IMAGE_INFO } from '../../constants/constant';
import { factsCategoryState } from '../../recoil/store';
import { useRecoilValue } from 'recoil';

export default function AddFactDetailForm({ setFactDetails, setShowFactDetail }) {
    const {
        showCropper,
        setShowCropper,
        crop,
        setCrop,
        handleCrop,
        onGallery,
        imageFile,
        imageUrl,
        onImageLoad,
        fileInputRef
    } = useMediaPicker(FACT_IMAGE_INFO);

    const factsCategoryList = useRecoilValue(factsCategoryState);
    const [selectedCategory, setSelectedCategory] = useState('Select Category');
    const [tags, setTags] = useState([]);
    const { register, setError, clearErrors, handleSubmit, formState: { errors } } = useForm();

    useEffect(() => {
        clearErrors('category');
    }, [selectedCategory])

    useEffect(() => {
        clearErrors('imageFile');
    }, [imageFile])

    useEffect(() => {
        if (tags.length > 2)
            clearErrors('tags');
    }, [tags])

    async function onSubmit(data) {

        let hasErrors = false;

        // Custom validation for imageFile
        if (!imageFile) {
            setError('imageFile', {
                type: 'manual',
                message: 'Image is mandatory*',
            });
            hasErrors = true;
        }

        // Custom validation for selectedCategory
        if (selectedCategory === 'Select Category') {
            setError('category', {
                type: 'manual',
                message: 'Please choose a category*',
            });
            hasErrors = true;
        }

        // Custom validation for tags
        if (tags.length < 3) {
            setError('tags', {
                type: 'manual',
                message: 'Please add atleast 3 tags*',
            });
            hasErrors = true;
        }

        if (!hasErrors) {
            data.imageFile = imageFile?.file;
            data.categories = [selectedCategory];
            data.tags = tags;

            data.title = data?.title?.trim();
            data.description = data?.description?.trim();
            data.article = data?.article?.trim();

            setFactDetails(data);
            setShowFactDetail(false);
        }
    }

    const handleDivClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const attachImageJsx = (
        <div
            onClick={handleDivClick}
            className="aspect-video w-full flex flex-row justify-center items-center rounded-lg cursor-pointer bg-gray-100"
        >
            <input
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={onGallery}
                ref={fileInputRef}
                style={{ display: 'none' }}
            />
            <div className="flex flex-col items-center">
                <Icon
                    icon='zondicons:add-solid'
                    color='#A3A3A3'
                    fontSize={28}
                />
                <span className="text-gray-500 text-center font-semibold mt-2 ml-2 text-sm font-quicksand">
                    Attach Photo
                </span>
            </div>
        </div>
    );

    const editImageJsx = (
        <div className='relative'>
            {imageFile && !showCropper && (
                <img
                    src={imageFile?.filePath}
                    className="w-full aspect-video rounded-lg object-cover"
                    alt="Selected"
                />
            )}
            <div
                onClick={handleDivClick}
                className="absolute top-4 right-4 h-9 w-9 cursor-pointer bg-black bg-opacity-40 hover:bg-opacity-60 rounded-full flex flex-row justify-center items-center"
            >
                <input
                    type="file"
                    accept="image/*"
                    onChange={onGallery}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                />
                <Icon
                    icon='ic:round-edit'
                    color='white'
                    fontSize={18}
                />
            </div>
        </div>
    );

    const imageCropperJsx = (
        showCropper && (
            <div className='fixed z-40 top-[5vh] right-[18vw] bottom-[5vh] left-[18vw] flex flex-col justify-center p-8 rounded-xl bg-gray-100'>
                <div className='flex justify-center mb-6'>
                    <ReactCrop
                        src={imageUrl}
                        crop={crop}
                        keepSelection
                        aspect={FACT_IMAGE_INFO.ratio}
                        onChange={(pixelCrop, percentageCrop) => setCrop(percentageCrop)}
                        onComplete={() => { }}
                    >
                        <img
                            src={imageUrl}
                            alt='upload'
                            onLoad={onImageLoad}
                            style={{ maxHeight: '75vh' }}

                        />
                    </ReactCrop>
                </div>
                <div className='flex justify-center'>
                    <div
                        onClick={handleCrop}
                        className='bg-custom-blue cursor-pointer text-white text-sm font-quicksand font-medium px-3 py-1 rounded-md'>
                        Crop & Save
                    </div>
                    <div
                        onClick={() => setShowCropper(false)}
                        className='bg-gray-200 cursor-pointer text-gray-800 text-sm ml-3 font-quicksand font-medium px-3 py-1 rounded-md'>
                        Cancel
                    </div>
                </div>
            </div>
        )
    )

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='p-6'>

            {imageFile ? editImageJsx : attachImageJsx}
            {imageCropperJsx}
            {
                errors.imageFile && (
                    <div className='text-red-500 mt-1 font-quicksand font-medium text-[11px]'>
                        {errors.imageFile?.message}
                    </div>
                )
            }

            <div className='mt-6'>
                <CustomDropDown
                    label='Category'
                    dropDownList={factsCategoryList}
                    selectedOption={selectedCategory}
                    setSelectedOption={setSelectedCategory}
                />
                {
                    errors.category && (
                        <div className='text-red-500 mt-1 font-quicksand font-medium text-[11px]'>
                            {errors.category?.message}
                        </div>
                    )
                }
            </div>

            <div className='mt-6'>
                <Label text='Title' />
                <InputField
                    name='title'
                    placeholder='Enter a concise and intriguing fact title...'
                    pattern={{ required: "Title is mandatory*" }}
                    register={register}
                    errors={errors}
                    clearErrors={clearErrors}
                />
            </div>

            <div className='mt-6'>
                <Label text='Short Description' />
                <TextArea
                    name='description'
                    placeholder='Provide a brief, captivating description...'
                    pattern={{ required: "Short Description is mandatory*" }}
                    register={register}
                    errors={errors}
                    rows={5}
                />
            </div>

            <div className='mt-6'>
                <Label text='Description' />
                <TextArea
                    name='article'
                    placeholder='Enter a detailed description...'
                    pattern={{ required: "Description is mandatory*" }}
                    register={register}
                    errors={errors}
                    rows={10}
                />
            </div>

            <div className='mt-6'>
                <Label text='Add Tags' />
                <AddTags
                    tags={tags}
                    setTags={setTags}
                />
                {
                    errors.tags && (
                        <div className='text-red-500 mt-1 font-quicksand font-medium text-[11px]'>
                            {errors.tags?.message}
                        </div>
                    )
                }
            </div>

            <div className='mt-6'>
                <Label text='Source URL' />
                <InputField
                    name='sourceUrl'
                    placeholder='Enter the source URL'
                    register={register}
                    errors={errors}
                    pattern={{
                        pattern: {
                            value: /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w-.,@?^=%&:/~+#]*)?$/,
                            message: "Enter valid url*"
                        }
                    }}
                />
            </div>

            <div className='mt-6'>
                <CustomButton
                    text='Next'
                    width='6rem'
                />
            </div>

        </form>
    )
}
