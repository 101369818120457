import React from 'react'
import FactsListForQuizDataTable from '../components/dataTables/FactsListForQuizDataTable'

export default function QuizPage() {
    return (
        <div className='mx-8'>
            <FactsListForQuizDataTable />
        </div>
    )
}
