
// <--------- Side Bar Item List --------->
export const sideBarMenuList = [
    {
        menuName: 'Dashboard',
        permission: 'DASHBOARD_MENU',
        icon: 'ic:round-dashboard',
        url: '/dashboard'
    },
    {
        menuName: 'Facts',
        permission: 'FACT_MENU',
        icon: 'mage:light-bulb-fill',
        url: '/facts'
    },
    {
        menuName: 'Category',
        permission: 'CATEGORY_MENU',
        icon: 'ic:round-category',
        url: '/categories'
    },
    {
        menuName: 'Quiz',
        permission: 'QUIZ_MENU',
        icon: 'iconamoon:clock-fill',
        url: '/quiz'
    },
    {
        menuName: 'Users',
        permission: 'USER_MENU',
        icon: 'bi:people-fill',
        url: '/users'
    },
    {
        menuName: 'Roles',
        permission: 'ROLE_MENU',
        icon: 'mingcute:user-setting-fill',
        url: '/roles'
    }
]

// <--------- Dashboard Statistics Overview List --------->
export const dashboardStatisticsOverviewList = [
    {
        statusName: 'Total Users',
        statusNumber: 1234,
        icon: 'tabler:bulb-filled',
        iconColor: '#2D74FF',
        iconBackgroundColor: '#EAF1FF'
    },
    {
        statusName: 'To Facts',
        statusNumber: 124,
        icon: 'mdi:approve',
        iconColor: '#14C25A',
        iconBackgroundColor: '#E8F9EE'
    },
    {
        statusName: 'In-Review Facts',
        statusNumber: 12,
        icon: 'ic:round-timer',
        iconColor: '#F38D15',
        iconBackgroundColor: '#FEF4E8'
    },
    {
        statusName: 'Rejected Facts',
        statusNumber: 12,
        icon: 'mdi:cross-circle',
        iconColor: '#E63152',
        iconBackgroundColor: '#FDEAEE'
    },
]

// <--------- Fact Statistics Overview List --------->
export const factStatisticsOverviewList = [
    // {
    //     statusName: 'Total Facts',
    //     statusNumber: 1234,
    //     icon: 'tabler:bulb-filled',
    //     iconColor: '#2D74FF',
    //     iconBackgroundColor: '#EAF1FF',
    //     statusCode: 'TOTAL'
    // },
    {
        statusName: 'Approved Facts',
        statusNumber: 124,
        icon: 'mdi:approve',
        iconColor: '#14C25A',
        iconBackgroundColor: '#E8F9EE',
        statusCode: 'APPROVED'
    },
    {
        statusName: 'In-Review Facts',
        statusNumber: 12,
        icon: 'ic:round-timer',
        iconColor: '#F38D15',
        iconBackgroundColor: '#FEF4E8',
        statusCode: 'IN_REVIEW'
    },
    {
        statusName: 'Reposted Facts',
        statusNumber: 12,
        icon: 'zondicons:repost',
        iconColor: '#8d33e6',
        iconBackgroundColor: '#8d33e615',
        statusCode: 'REPOSTED'
    },
    {
        statusName: 'Rejected Facts',
        statusNumber: 12,
        icon: 'mdi:cross-circle',
        iconColor: '#E63152',
        iconBackgroundColor: '#FDEAEE',
        statusCode: 'REJECTED'
    },
]

// <--------- User Statistics Overview List --------->
export const userStatisticsOverviewList = [
    {
        statusName: 'Total Users',
        statusNumber: 1234,
        icon: 'bi:people-fill',
        iconColor: '#2D74FF',
        iconBackgroundColor: '#EAF1FF'
    },
    {
        statusName: 'Total Admins',
        statusNumber: 124,
        icon: 'fa6-solid:user-tie',
        iconColor: '#14C25A',
        iconBackgroundColor: '#E8F9EE'
    },
    {
        statusName: 'Total Contributors',
        statusNumber: 12,
        icon: 'ri:user-add-fill',
        iconColor: '#F38D15',
        iconBackgroundColor: '#FEF4E8'
    },
    // {
    //     statusName: 'Rejected Facts',
    //     statusNumber: 12,
    //     icon: 'mdi:cross-circle',
    //     iconColor: '#E63152',
    //     iconBackgroundColor: '#FDEAEE'
    // },
]

// <--------- Fact Status List --------->
export const factStatusList = [
    {
        status: 'APPROVED',
        displayText: 'Approved',
        textColor: '#14C25A',
        backgroundColor: '#E8F9EE'
    },
    {
        status: 'IN_REVIEW',
        displayText: 'In_Review',
        textColor: '#F38D15',
        backgroundColor: '#FEF4E8'
    },
    {
        status: 'REJECTED',
        displayText: 'Rejected',
        textColor: '#E63152',
        backgroundColor: '#FDEAEE'
    },
    {
        status: 'REPOSTED',
        displayText: 'Reposted',
        textColor: '#8d33e6',
        backgroundColor: '#8d33e615'
    },
]

// <--------- Fact Action Button List --------->
export const factActionButtonList = [
    {
        icon: 'icon-park-outline:log',
        action: 'VIEW_LOG_FACT',
        toolTip: 'Log'
    },
    {
        icon: 'tabler:status-change',
        action: 'CHANGE_STATUS_FACT',
        toolTip: 'Chnage Status'
    },
    {
        icon: 'tabler:edit',
        action: 'EDIT_FACT',
        toolTip: 'Edit',

    },
]

// <--------- Fact Of The Day Action Button List --------->
export const factOfTheDayActionButtonList = [
    {
        icon: 'game-icons:click',
        action: 'ASSIGN_FACT_OF_THE_DAY',
        toolTip: 'Assign'
    }
]

// <--------- Category Action Button List --------->
export const categoryActionButtonList = [
    {
        icon: 'tabler:edit',
        action: 'EDIT_CATEGORY',
        toolTip: 'Edit',

    },
    {
        icon: 'mdi:lightbulb-on',
        action: 'FACTS',
        toolTip: 'Facts'
    }
]

// <--------- User Action Button List --------->
export const userActionButtonList = [
    {
        icon: 'material-symbols:assignment-add',
        action: 'ASSIGN',
        toolTip: 'Assign Role'
    },
    {
        icon: 'mdi:lightbulb-on',
        action: 'FACTS',
        toolTip: 'Facts'
    },
]

// <--------- User Role List --------->
export const userRoleList = [
    {
        role: 'ADMIN',
        roleName: 'Admin',
        textColor: '#14C25A',
        backgroundColor: '#E8F9EE'
    },
    {
        role: 'CONTRIBUTOR',
        roleName: 'Contributor',
        textColor: '#F38D15',
        backgroundColor: '#FEF4E8'
    },
    {
        role: 'USER',
        roleName: 'User',
        textColor: '#2D74FF',
        backgroundColor: '#EAF1FF'
    }
]

// <--------- Permission List --------->
export const permissionsList = [
    {
        permissionName: 'View',
        permissionCode: 'view',
        isGranted: false
    },
    {
        permissionName: 'Create',
        permissionCode: 'create',
        isGranted: false
    },
    {
        permissionName: 'Update',
        permissionCode: 'update',
        isGranted: false
    },
    {
        permissionName: 'Delete',
        permissionCode: 'delete',
        isGranted: false
    }
]


// <--------- Question Options List --------->
// export const questionOptionsList = [
//     {
//         optionName: 'a',
//         optionColor: '#2D74FF',
//         optionBackgroundColor: '#EAF1FF'
//     },
//     {
//         optionName: 'b',
//         optionColor: '#14C25A',
//         optionBackgroundColor: '#E8F9EE'
//     },
//     {
//         optionName: 'c',
//         optionColor: '#F38D15',
//         optionBackgroundColor: '#FEF4E8'
//     },
//     {
//         optionName: 'd',
//         optionColor: '#E63152',
//         optionBackgroundColor: '#FDEAEE'
//     },
// ]

export const questionOptionsList = [
    {
        optionName: 'A',
        optionColor: 'bg-yellow-400',
        optionBackgroundColor: 'bg-yellow-200'
    },
    {
        optionName: 'B',
        optionColor: 'bg-green-400',
        optionBackgroundColor: 'bg-green-200'
    },
    {
        optionName: 'C',
        optionColor: 'bg-purple-400',
        optionBackgroundColor: 'bg-purple-200'
    },
    {
        optionName: 'D',
        optionColor: 'bg-cyan-400',
        optionBackgroundColor: 'bg-cyan-200'
    },
]

// <--------- Fact Category List --------->
export const factCategoryList = [
    "Science",
    "History",
    "Technology",
    "Geography",
    "Wildlife",
    "Pets",
    "Insects",
    "Space",
    "Health",
    "Environment",
    "Sports",
    "Food",
    "Art",
    "Music",
    "Literature",
    "Mythology",
    "Psychology",
    "Culture",
    "Mathematics",
    "Economics",
    "Politics",
    "Languages"
];

export const speechText = `A punt gun is an extremely large shotgun used in the 19th and early 20th centuries to shoot large numbers of waterfowl for commercial harvesting operations. These weapons are characteristically too large for an individual to fire from the shoulder or often carry by themselves. Still, unlike artillery pieces, punt guns can be aimed and fired by a single man from amount.
 
In this case, the amount is typically a small watercraft. Many early models appear similar to oversized versions of shoulder weapons of the time with full-length wooden stocks with a normal-sized shoulder stock. Most later variations do away with the full-length stock, especially more modern models, and have mounting hardware fixed to the gun to allow them to be fitted to a pintle.
 
Punt guns were usually custom-designed and varied widely but could have bore diameters exceeding 2 inches (51 mm) and fire over a pound (0.45 kg) of a shot at a time. A single shot could kill over 50 waterfowl resting on the water's surface. They were too big to hold, and the recoil was so large that they had to be mounted directly on punts used for hunting, hence their name. Hunters would maneuver their punts quietly into line and range of the flock using poles or oars to avoid startling them.
 
Generally, the gun was fixed to the punt; thus, the hunter would maneuver the entire boat to aim the gun. The guns were sufficiently powerful, and the punts themselves sufficiently small that firing the gun often propelled the punt backward several inches or more. Hunters could work in fleets of up to around ten punts to improve efficiency.`