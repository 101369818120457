import React, { useState } from 'react'
import showToast from '../../utils/toast';
import { ERROR_VARIANT, SUCCESS_VARIANT } from '../../constants/constant';
import AddNewCategoryForm from '../forms/AddNewCategoryForm';
import { addNewCategoryApi, updateCategoryImageApi } from '../../rest/CategoryApi';
import { useSetRecoilState } from 'recoil';
import { factsCategoryState } from '../../recoil/store';

export default function AddNewCategory({ setData, setIsModalOpen }) {
    const [isLoading, setIsLoading] = useState(false);
    const setFactsCategory = useSetRecoilState(factsCategoryState);

    // <--------- Update Category Row --------->
    async function updateCategoryRow(newCategory) {
        setData(prevData => [...prevData, newCategory]);
    }

    // <--------- Add Category Image --------->
    async function addCategoryImage(categoryId, body) {
        const result = await updateCategoryImageApi(categoryId, body);
        if (result?.status === 200) {
            showToast('Category added', SUCCESS_VARIANT);
            setIsModalOpen(false);
        }
        else
            showToast('No image uploaded', ERROR_VARIANT);
    }

    // <--------- Form submit function --------->
    async function handleCategorySubmission(data) {
        const imageFile = data.imageFile;
        delete data.imageFile;
        const formData = new FormData();
        formData.append('image', imageFile);

        setIsLoading(true);
        const result = await addNewCategoryApi(data);
        if (result?.status === 201) {
            await addCategoryImage(result.data.id, formData);
            await updateCategoryRow({ ...result.data, imageUrl: `v1/categories/images/${result.data.id}.jpg` });
            await setFactsCategory((prevCat) => [...prevCat, result.data.name]);
        }
        else if (result?.status === 400)
            showToast(result?.data?.message, ERROR_VARIANT);
        else
            showToast('Something went wrong', ERROR_VARIANT);
        setIsLoading(false);
    }

    return (
        <AddNewCategoryForm
            handleCategorySubmission={handleCategorySubmission}
        />
    )
}
