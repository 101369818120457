import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import { Icon } from '@iconify/react';
import { categoryActionButtonList } from '../../constants/dummyData';
import CustomModal from '../modals/CustomModal';
import Pagination from '../common/Pagination';
import { CATEGORY_IMAGE_URL } from '../../constants/constant';
import { appLogoGrayScale } from '../../constants/ImagesAndIcons';
import { dateConversion } from '../../utils/displayDate';
import { customBlue } from '../../constants/color';
import Loader from '../common/Loader';
import NoDataFound from '../common/NoDataFound';
import { isPermissionGranted } from '../../utils/rolePermissionHandler';
import { getAllCategoriesApi } from '../../rest/CategoryApi';
import AddNewCategory from '../category/AddNewCategory';
import EditCategory from '../category/EditCategory';
import FactListsOfCategoryDataTable from './FactListsOfCategoryDataTable';

// Search component for global filtering
const GlobalFilter = ({ globalFilter, setGlobalFilter, isModalOpen, setIsModalOpen, showAddNewButton }) => {
    return (
        <div className={`flex ${showAddNewButton ? 'justify-between' : 'justify-end'} px-6 items-center h-[4.5rem] bg-gray-50 rounded-lg`}>
            <div className='flex items-center h-8'>
                <input
                    value={globalFilter || ''}
                    onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                    className="px-4 py-1 h-8 min-w-56 border border-gray-400 rounded outline-none text-sm font-quicksand"
                    placeholder="Search..."
                />
                <button
                    className="px-3 h-full ml-2 bg-custom-blue text-white rounded disabled:opacity-50 text-sm font-medium font-quicksand"
                >
                    <Icon
                        icon='mingcute:search-line'
                        color='white'
                        fontSize={20}
                    />
                </button>
            </div>
            {
                showAddNewButton && (
                    <div className='h-8'>
                        <button
                            onClick={() => setIsModalOpen(true)}
                            className="px-3 h-full ml-2 bg-custom-blue text-white rounded disabled:opacity-50 text-sm font-medium font-quicksand"
                        >
                            Add new category
                        </button>
                    </div>
                )
            }

        </div>
    );
};

const CategoryDataTable = ({ showAddNewButton = true }) => {
    const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentMenu, setCurrentMenu] = useState('EDIT');
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(true);

    function actionButtonJsx(item, row) {
        const { action, icon, toolTip } = item;

        // Render the action button
        return (
            <div
                onClick={(event) => handleClick(event, action, row.original)}
                className="relative group h-9 w-9 flex justify-center items-center cursor-pointer rounded-full hover:bg-gray-200 focus:bg-gray-200"
            >
                <Icon
                    icon={icon}
                    color="gray"
                    fontSize={22}
                />
                <div
                    className="absolute z-40 whitespace-nowrap top-full left-1/2 transform -translate-x-1/2 mt-1 bg-gray-500 text-white text-xs font-medium py-1 px-2 rounded-md shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                >
                    {toolTip}
                </div>
            </div>
        );
    }


    // Define columns and data
    const columns = useMemo(() => [
        {
            Header: 'Image',
            accessor: 'imageUrl',
            disableSortBy: true,
            Cell: memo(({ row }) => (
                row.original.imageUrl ? (
                    <img
                        src={row.original.imageUrl ? `${CATEGORY_IMAGE_URL}${row.original.imageUrl}` : appLogoGrayScale}
                        className="w-10 aspect-[3/4] rounded-lg"
                        alt="Thumbnail"
                    />
                ) : (
                    <div className='flex justify-center items-center bg-gray-300 w-12 aspect-[3/4] rounded-lg'>
                        <Icon
                            icon='heroicons:magnifying-glass-16-solid'
                            color='white'
                            fontSize={28}
                        />
                    </div>
                )

            )),
        },
        {
            Header: 'Category Name',
            accessor: 'name',
            disableSortBy: true,
        },
        {
            Header: 'Action',
            accessor: 'action',
            disableSortBy: true,
            Cell: memo(({ row }) => {
                return (
                    <div className="flex items-center gap-1">
                        {
                            categoryActionButtonList.map((item, index) => {
                                return actionButtonJsx(item, row);
                            })
                        }
                    </div>

                )
            })
        },
    ], [data, setData]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setPageSize,
        state,
        setGlobalFilter,
        preGlobalFilteredRows,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 10 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const { globalFilter, pageSize } = state;

    // Fetch categories from the API
    const fetchData = async () => {
        setIsLoading(true);
        const result = await getAllCategoriesApi(pageNumber, pageSize);
        if (result?.status === 200) {
            setData(result.data.results);
            setTotalPages(result.data.totalPage);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [pageNumber, pageSize]);

    function handleClick(event, menu, category) {
        event.stopPropagation();
        setCurrentMenu(menu.toUpperCase());
        setSelectedCategory(category);
        if (menu !== 'VIEW')
            setIsModalOpen(true);
    }

    return (
        <div className="p-8 bg-white rounded-xl ">
            {
                isAddCategoryModalOpen && (
                    <CustomModal
                        isModalOpen={isAddCategoryModalOpen}
                        setIsModalOpen={setIsAddCategoryModalOpen}>
                        <AddNewCategory
                            setData={setData}
                            setIsModalOpen={setIsAddCategoryModalOpen}
                        />
                    </CustomModal>
                )
            }

            {isModalOpen && (
                <CustomModal
                    width={currentMenu === 'EDIT_CATEGORY' ? 'w-[500px]' : '100%'}
                    showCross={currentMenu === 'STATUS' ? false : true}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}>
                    {
                        currentMenu === 'EDIT_CATEGORY' ? (
                            <EditCategory
                                categoryDetails={selectedCategory}
                                setData={setData}
                                setIsModalOpen={setIsModalOpen}
                            />
                        ) : currentMenu === 'FACTS' && (
                            <FactListsOfCategoryDataTable
                                selectedCategory={selectedCategory}
                            />
                        )
                        // : (
                        //     <ViewFact
                        //         setData={setData}
                        //         setIsModalOpen={setIsModalOpen}
                        //         factId={selectedCategory?.id}
                        //     />
                        // )
                    }
                </CustomModal>
            )}

            {/* Global Search Filter */}
            <GlobalFilter
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                preGlobalFilteredRows={preGlobalFilteredRows}
                isModalOpen={isAddCategoryModalOpen}
                setIsModalOpen={setIsAddCategoryModalOpen}
                showAddNewButton={showAddNewButton}
            />

            {/* Data Table */}
            <div className="overflow-x-auto mt-4 pb-4">
                <table {...getTableProps()} className="min-w-full bg-white">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className="px-4 py-2 bg-white text-left text-sm font-bold text-gray-500 tracking-wider border-b border-gray-300 cursor-pointer font-quicksand "
                                    >
                                        <div className='flex items-center'>
                                            {column.render('Header')}
                                            <span className="ml-2">
                                                {!column.disableSortBy && (
                                                    column.isSorted ? (
                                                        column.isSortedDesc ? (
                                                            <Icon
                                                                icon="eva:arrow-down-fill"
                                                                color="gray"
                                                                fontSize={14}
                                                            />
                                                        ) : (
                                                            <Icon
                                                                icon="eva:arrow-up-fill"
                                                                color="gray"
                                                                fontSize={14}
                                                            />
                                                        )
                                                    ) : (
                                                        <Icon
                                                            icon="flowbite:sort-outline"
                                                            color="gray"
                                                            fontSize={14}
                                                        />
                                                    )
                                                )}
                                            </span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {isLoading ? (
                            <tr>
                                <td
                                    colSpan={columns.length} // Spans all columns
                                    className="px-4 py-10 text-sm font-quicksand font-semibold text-gray-700 border-b border-gray-300 text-center"
                                >
                                    <Loader
                                        color={customBlue}
                                        size="2.5rem"
                                        borderWidth='3px'
                                    />
                                </td>
                            </tr>
                        ) :

                            page.length > 0 ? (
                                page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()} className="hover:bg-gray-50">
                                            {row.cells.map((cell) => (
                                                <td
                                                    onClick={(event) => handleClick(event, 'VIEW', row.original)}
                                                    {...cell.getCellProps()}
                                                    className="px-4 py-4 text-sm font-quicksand font-semibold text-gray-700 border-b border-gray-300 cursor-pointer"
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            ))}
                                        </tr>
                                    );
                                })) : (
                                <tr>
                                    <td
                                        colSpan={columns.length}
                                        className='border-b border-gray-300'
                                    >
                                        <NoDataFound
                                            message={'No Category Found'}
                                        />
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>

            {/* Pagination Controls */}
            <div className="mt-4">
                <Pagination
                    totalPages={totalPages}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                />
            </div>
        </div>
    );
};

export default CategoryDataTable;
