import React, { useState } from 'react'
import { factStatusList } from '../../constants/dummyData';
import { updateFactStatusApi } from '../../rest/FactApi';
import ConfirmationModal from '../modals/ConfirmationModal';
import showToast from '../../utils/toast';
import { ERROR_VARIANT, SUCCESS_VARIANT } from '../../constants/constant';
import { Label, TextArea } from '../common/Form';
import { useForm } from 'react-hook-form';

export default function UpdateFactStatus({ setIsModalOpen, setData, fact, setFactsCount }) {
    const [currentStatus, setCurrentStatus] = useState(fact?.status || '');
    const [tempStatus, setTempStatus] = useState(fact?.status || '');
    const [isLoading, setIsLoading] = useState(false);
    const { register, setError, handleSubmit, formState: { errors }, clearErrors } = useForm();

    // <--------- Update Fact Status In Row --------->
    function updateFactRow(factId, status) {
        setData(prevData =>
            prevData.map(fact =>
                fact.id === factId ? { ...fact, status } : fact
            )
        );
    }

    // <--------- Update Fact Status List --------->
    function getStatusList(currentStatus) {
        let filteredStatusList;
        if (currentStatus === 'REPOSTED')
            filteredStatusList = factStatusList;
        else
            filteredStatusList = factStatusList.filter((item) => item.status !== 'REPOSTED');

        return (
            <>
                {filteredStatusList.map((item) => (
                    <div
                        key={item.id || item.status} // Use a unique property if available
                        onClick={() => {
                            if (item.status !== 'REPOSTED') {
                                setTempStatus(item.status);
                            }
                        }}
                        style={{
                            backgroundColor: tempStatus === item.status ? item.backgroundColor : '#f3f4f6',
                            color: tempStatus === item.status ? item.textColor : 'black',
                        }}
                        className="font-quicksand w-full flex justify-center items-center text-sm font-medium rounded border-white h-full">
                        {item.displayText}
                    </div>
                ))}
            </>
        );

    }

    // <--------- Handle Confirmation --------->
    async function handleConfirmation(data) {
        if (currentStatus === tempStatus) {
            showToast('Please change the status', ERROR_VARIANT);
            return;
        }

        if (tempStatus === 'REJECTED' && !data.comment) {
            setError('comment', {
                type: "manual",
                message: "Comment is mandatory for rejected status"
            });
            return;
        }

        const body = {
            status: tempStatus,
            comment: data?.comment
        }

        setIsLoading(true);
        const result = await updateFactStatusApi(fact?.id, body);
        if (result?.status === 200) {
            updateFactRow(fact?.id, tempStatus);
            setFactsCount((allFactsCount) =>
                allFactsCount.map((item) => {
                    if (item.status === tempStatus) {
                        return { ...item, count: item.count + 1 };
                    } else if (item.status === currentStatus) {
                        return { ...item, count: item.count - 1 };
                    }
                    return item;
                })
            );
            setCurrentStatus(tempStatus);
            setIsModalOpen(false);
            showToast('Fact status changed', SUCCESS_VARIANT);
        }
        setIsLoading(false);
    }

    // <--------- Handle Custom Submit --------->
    function customSubmit(e) {
        e.preventDefault();
        clearErrors();
        handleSubmit(handleConfirmation)();
    }

    return (
        <ConfirmationModal
            title='Change Status?'
            setIsModalOpen={setIsModalOpen}
            handleConfirmation={customSubmit}
            isLoading={isLoading}>
            <form onSubmit={customSubmit} className=' rounded-lg p-6'>
                <div>
                    <Label text='Status' />
                    <div className='h-7 gap-2 flex items-center overflow-hidden  border-gray-200 cursor-pointer'>
                        {getStatusList(currentStatus)}
                    </div>
                </div>

                <div className='mt-4'>
                    <Label text='Comment' />
                    <TextArea
                        name='comment'
                        placeholder='Write here'
                        register={register}
                        errors={errors}
                        rows={3}
                    />
                </div>
            </form>
        </ConfirmationModal>
    )
}
