import { categoryInstance } from "./AxiosInstance";

// <--------- Get All Category --------->
export const getAllCategoriesApi = async (page, size) => {
    try {
        const result = await categoryInstance.get(`categories?page=${page}&size=${size}`);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Add New Category --------->
export const addNewCategoryApi = async (body) => {
    try {
        const result = await categoryInstance.post('categories', body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Update Category Image --------->
export const updateCategoryImageApi = async (categoryId, body) => {
    try {
        const result = await categoryInstance.put(`categories/${categoryId}/images`, body);
        return result;
    } catch (error) {
        return error?.response;
    }
}