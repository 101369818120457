import React, { useCallback, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { currentMenuState, factsCategoryState, isAuthenticatedState, isDataFetchingState } from '../recoil/store';
import SideBar from '../components/common/SideBar';
import Navbar from '../components/common/Navbar';
import { sideBarMenuList } from '../constants/dummyData';
import Loader from '../components/common/Loader';
import BackDrop from '../components/common/BackDrop';
import { getAllCategoriesApi } from '../rest/CategoryApi';

export default function ProtectedRoute() {
    const isLoggedIn = useRecoilValue(isAuthenticatedState);
    const isDataFetching = useRecoilValue(isDataFetchingState);
    const setFactsCategory = useSetRecoilState(factsCategoryState)
    const setCurrentMenu = useSetRecoilState(currentMenuState);
    const location = useLocation();

    const getSideBarMenuObj = useCallback(((url) => sideBarMenuList?.find(option => option.url === url)), []);

    useEffect(() => {
        setCurrentMenu(getSideBarMenuObj(location.pathname)?.menuName);
    }, [location.pathname])

    // <--------- Fetch categories from the API --------->
    const fetchCategoryList = async () => {
        const result = await getAllCategoriesApi(0, 1000);
        if (result?.status === 200) {
            const factsCategoryList = result.data?.results?.map((item) => item.name);
            setFactsCategory(factsCategoryList);
        }
    };

    useEffect(() => {
        fetchCategoryList();
    }, []);

    return (
        <div className='flex justify-center items-center h-[100vh] bg-white relative'>
            <div className='flex h-[90vh] w-[90vw] rounded-3xl shadow-2xl overflow-hidden'>
                <SideBar />
                <div className='bg-gray-100 w-full h-full'>
                    <Navbar />
                    <div className='h-[80.8vh] relative'>
                        {
                            isDataFetching && (
                                <BackDrop />
                            )
                        }

                        <div className='h-full py-8 overflow-y-auto'>
                            {isLoggedIn ? <Outlet /> : <Navigate to='/login' />}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}