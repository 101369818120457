import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { CustomButton, InputField, Label } from '../common/Form';
import useMediaPicker from '../../hooks/useMediaPicker';
import { Icon } from '@iconify/react/dist/iconify.js';
import { ReactCrop } from 'react-image-crop';
import { CATEGORY_IMAGE_INFO } from '../../constants/constant';

export default function AddNewCategoryForm({ handleCategorySubmission }) {
    const {
        showCropper,
        setShowCropper,
        crop,
        setCrop,
        handleCrop,
        onGallery,
        imageFile,
        imageUrl,
        onImageLoad,
        fileInputRef
    } = useMediaPicker(CATEGORY_IMAGE_INFO);

    const { register, setError, clearErrors, handleSubmit, formState: { errors } } = useForm();

    useEffect(() => {
        clearErrors('imageFile');
    }, [imageFile])

    async function onSubmit(data) {

        let hasErrors = false;

        // Custom validation for imageFile
        if (!imageFile) {
            setError('imageFile', {
                type: 'manual',
                message: 'Image is mandatory*',
            });
            hasErrors = true;
        }

        if (!hasErrors) {
            data.imageFile = imageFile?.file;
            handleCategorySubmission(data);
        }
    }

    const handleDivClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const attachImageJsx = (
        <div
            onClick={handleDivClick}
            className="aspect-[3/4] w-64 flex flex-row justify-center items-center rounded-lg cursor-pointer bg-gray-100"
        >
            <input
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={onGallery}
                ref={fileInputRef}
                style={{ display: 'none' }}
            />
            <div className="flex flex-col items-center">
                <Icon
                    icon='zondicons:add-solid'
                    color='#A3A3A3'
                    fontSize={28}
                />
                <span className="text-gray-500 text-center font-semibold mt-2 ml-2 text-sm font-quicksand">
                    Attach Photo
                </span>
            </div>
        </div>
    );

    const editImageJsx = (
        <div className='relative'>
            {imageFile && !showCropper && (
                <img
                    src={imageFile?.filePath}
                    className="w-64 aspect-[3/4] rounded-lg object-cover"
                    alt="Selected"
                />
            )}
            <div
                onClick={handleDivClick}
                className="absolute top-4 right-4 h-9 w-9 cursor-pointer bg-black bg-opacity-40 hover:bg-opacity-60 rounded-full flex flex-row justify-center items-center"
            >
                <input
                    type="file"
                    accept="image/*"
                    onChange={onGallery}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                />
                <Icon
                    icon='ic:round-edit'
                    color='white'
                    fontSize={18}
                />
            </div>
        </div>
    );

    const imageCropperJsx = (
        showCropper && (
            <div className='fixed z-40 top-[5vh] right-[18vw] bottom-[5vh] left-[18vw] flex flex-col justify-center p-8 rounded-xl bg-gray-100'>
                <div className='flex justify-center mb-6'>
                    <ReactCrop
                        src={imageUrl}
                        crop={crop}
                        keepSelection
                        aspect={CATEGORY_IMAGE_INFO.ratio}
                        onChange={(pixelCrop, percentageCrop) => setCrop(percentageCrop)}
                        onComplete={() => { }}
                    >
                        <img
                            src={imageUrl}
                            alt='upload'
                            onLoad={onImageLoad}
                            style={{ maxHeight: '75vh' }}

                        />
                    </ReactCrop>
                </div>
                <div className='flex justify-center'>
                    <div
                        onClick={handleCrop}
                        className='bg-custom-blue cursor-pointer text-white text-sm font-quicksand font-medium px-3 py-1 rounded-md'>
                        Crop & Save
                    </div>
                    <div
                        onClick={() => setShowCropper(false)}
                        className='bg-gray-200 cursor-pointer text-gray-800 text-sm ml-3 font-quicksand font-medium px-3 py-1 rounded-md'>
                        Cancel
                    </div>
                </div>
            </div>
        )
    )

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='p-6'>

            <div className='flex justify-center'>
                {imageFile ? editImageJsx : attachImageJsx}
            </div>
            {imageCropperJsx}
            {
                errors.imageFile && (
                    <div className='text-red-500 mt-1 font-quicksand font-medium text-[11px]'>
                        {errors.imageFile?.message}
                    </div>
                )
            }

            <div className='mt-6'>
                <Label text='Category Name' />
                <InputField
                    name='name'
                    placeholder='Enter category name'
                    pattern={{ required: "Name is mandatory*" }}
                    register={register}
                    errors={errors}
                    clearErrors={clearErrors}
                />
            </div>

            <div className='mt-6'>
                <CustomButton
                    text='Add'
                    width='6rem'
                />
            </div>

        </form>
    )
}
