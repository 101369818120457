import React, { useState } from 'react'
import showToast from '../../utils/toast';
import { ERROR_VARIANT, SUCCESS_VARIANT } from '../../constants/constant';
import { updateCategoryImageApi } from '../../rest/CategoryApi';
import EditCategoryForm from '../forms/EditCategoryForm';

export default function EditCategory({ setData, setIsModalOpen, categoryDetails }) {
    const [isLoading, setIsLoading] = useState(false);
    const [refreshKey, setrefreshKey] = useState(0);

    // <--------- Update Category Row --------->
    async function updateCategoryRow(editedCategory) {
        setData(prevData =>
            prevData.map(category =>
                category.id === categoryDetails?.id ? editedCategory : category
            )
        );
    }

    // <--------- Update Category Image --------->
    async function updateCategoryImage(categoryId, body) {
        const result = await updateCategoryImageApi(categoryId, body);
        if (result?.status === 200) {
            setrefreshKey((prev) => prev + 1);
            updateCategoryRow({ ...categoryDetails, imageUrl: `${categoryDetails?.imageUrl}?key=${refreshKey}` })
            showToast('Category edited', SUCCESS_VARIANT);
            setIsModalOpen(false);
        }
        else
            showToast('No image uploaded', ERROR_VARIANT);
    }

    // <--------- Form submit function --------->
    async function handleCategorySubmission(data) {
        const imageFile = data.imageFile;
        delete data.imageFile;
        const formData = new FormData();
        formData.append('image', imageFile);

        setIsLoading(true);
        await updateCategoryImage(categoryDetails?.id, formData);
        setIsLoading(false);
    }

    return (
        <EditCategoryForm
            categoryDetails={categoryDetails}
            handleCategorySubmission={handleCategorySubmission}
            isLoading={isLoading}
        />
    )
}
