import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import { Icon } from '@iconify/react';
import { factStatusList } from '../../constants/dummyData';
import CustomModal from '../modals/CustomModal';
import ViewFact from '../fact/ViewFact';
import Pagination from '../common/Pagination';
import { getAllFactsApi, getSingleFactApi, postFactOfTheDayApi } from '../../rest/FactApi';
import { factsCategoryState, userState } from '../../recoil/store';
import { useRecoilValue } from 'recoil';
import { FACT_IMAGE_URL, SUCCESS_VARIANT } from '../../constants/constant';
import { appLogoGrayScale } from '../../constants/ImagesAndIcons';
import { dateConversion } from '../../utils/displayDate';
import { customBlue } from '../../constants/color';
import Loader from '../common/Loader';
import NoDataFound from '../common/NoDataFound';
import moment from 'moment';
import showToast from '../../utils/toast';
import { CustomDropDown } from '../common/Form';

const getFactStatusObj = (status) => factStatusList?.find(option => option.status === status);

// Search component for global filtering
const GlobalFilter = ({ globalFilter, setGlobalFilter, selectedCategory, setSelectedCategory }) => {
    const factsCategoryList = useRecoilValue(factsCategoryState);

    return (
        <div className={`flex justify-between px-6 items-center h-[4.5rem] bg-gray-50 rounded-lg`}>
            <div className='w-40'>
                <CustomDropDown
                    dropDownList={['All', ...factsCategoryList]}
                    selectedOption={selectedCategory}
                    setSelectedOption={setSelectedCategory}
                    dropDownGap='3rem'
                />
            </div>

            <div className='flex items-center h-8'>
                <input
                    value={globalFilter || ''}
                    onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                    className="px-4 py-1 h-8 min-w-56 border border-gray-400 rounded outline-none text-sm font-quicksand"
                    placeholder="Search..."
                />
                <button
                    className="px-3 h-full ml-2 bg-custom-blue text-white rounded disabled:opacity-50 text-sm font-medium font-quicksand"
                >
                    <Icon
                        icon='mingcute:search-line'
                        color='white'
                        fontSize={20}
                    />
                </button>
            </div>

        </div>
    );
};

const FactsListForQuizDataTable = ({
    showAddNewButton = true,
    selectedDate,
    setMarkedDates,
    factsList,
    setFactsList,
    setQuizQuestions
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentMenu, setCurrentMenu] = useState('EDIT');
    const [currentFact, setCurrentFact] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('Category')
    const [data, setData] = useState([]);
    const user = useRecoilValue(userState);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [isAssigningFactOfTheDay, setIsAssigningFactOfTheDay] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const formattedDate = moment(selectedDate).format('MMM DD, YYYY');

    function actionButtonJsx(item, row) {
        const { action, icon, toolTip } = item;

        // Render the action button
        return (
            <div
                onClick={(event) => handleClick(event, action, row.original)}
                className="relative group h-9 w-9 flex justify-center items-center cursor-pointer rounded-full hover:bg-gray-200 focus:bg-gray-200"
            >
                <Icon
                    icon={icon}
                    color="gray"
                    fontSize={22}
                />
                <div
                    className="absolute z-40 whitespace-nowrap top-full left-1/2 transform -translate-x-1/2 mt-1 bg-gray-500 text-white text-xs font-medium py-1 px-2 rounded-md shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                >
                    {toolTip}
                </div>
            </div>
        );
    }


    // Define columns and data
    const columns = useMemo(() => [
        {
            Header: 'FactId',
            accessor: 'id',
            disableSortBy: true
        },
        {
            Header: 'Image',
            accessor: 'thumbnailUrl',
            disableSortBy: true,
            Cell: memo(({ row }) => (
                <img
                    src={row.original.thumbnailUrl ? `${FACT_IMAGE_URL}${row.original.thumbnailUrl}` : appLogoGrayScale}
                    className="w-14 h-10 rounded-lg"
                    alt="Thumbnail"
                />
            )),
        },
        {
            Header: 'Category',
            accessor: 'categories',
            disableSortBy: true,
            Cell: memo(({ row }) => {
                return (
                    <div
                        className='h-7 flex justify-center items-center rounded-md bg-gray-100'>
                        <div
                            className='font-quicksand font-semibold text-xs'>
                            {row.original.categories[0]}
                        </div>
                    </div>
                )
            }),
        },
        {
            Header: 'Title',
            accessor: 'title',
            disableSortBy: true
        },
        {
            Header: 'Status',
            accessor: 'status',
            disableSortBy: true,
            Cell: memo(({ row }) => (
                <div
                    style={{ backgroundColor: getFactStatusObj(row.original.status)?.backgroundColor }}
                    className='h-7 w-24 flex justify-center items-center rounded-md'>
                    <div
                        style={{ color: getFactStatusObj(row.original.status)?.textColor }}
                        className='font-quicksand font-bold text-xs'>
                        {row.original.status}
                    </div>
                </div>
            )),
        },
        {
            Header: 'Created By',
            accessor: 'createdBy',
            disableSortBy: true
        },
        {
            Header: 'Created Date',
            accessor: 'createdAt',
            disableSortBy: false,
            Cell: memo(({ row }) => {
                const { formattedDate1, formattedTime } = dateConversion(row.original.createdAt);
                return (
                    <div
                        className='font-quicksand font-semibold text-sm text-gray-700'>
                        {formattedDate1 + ' ' + formattedTime}
                    </div>
                )
            }),
        },
        {
            Header: '',
            accessor: 'select',
            disableSortBy: true,
            Cell: memo(({ row }) => {
                return (
                    <div
                        className='flex items-center'>
                        <input
                            type='checkbox'
                            checked={factsList.some(fact => fact?.factId === row?.original?.id)}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleFactSelection(row);
                            }}
                            onChange={() => { }}
                            className={`h-4 w-4 mr-2 cursor-pointer checked:bg-sky-500 border-2 rounded-md border-gray-400 checked:border-sky-500`}
                        />
                    </div>
                )
            })
        }
    ], [data, setData]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setPageSize,
        state,
        setGlobalFilter,
        preGlobalFilteredRows,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 10, hiddenColumns: ['id'] }
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const { globalFilter, pageSize } = state;

    // Fetch facts data from the API
    const fetchData = async () => {
        let updatedCategory;
        if (selectedCategory === 'Category' || selectedCategory === 'All') {
            updatedCategory = []
        } else {
            updatedCategory = [selectedCategory]
        }
        setIsLoading(true);
        const result = await getAllFactsApi(user, null, ['APPROVED'], updatedCategory, pageNumber, pageSize);
        if (result?.status === 200) {
            setData(result.data.results);
            setTotalPages(result.data.totalPage);
        }
        else if (result?.status === 404 && result?.data?.code === 'NOT_FOUND') {
            setData([]);
            setTotalPages(1);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [pageNumber, pageSize, selectedCategory]);

    function handleClick(event, menu, fact) {
        event.stopPropagation();
        setCurrentMenu(menu.toUpperCase());
        setCurrentFact(fact);
        setIsModalOpen(true);
    }

    // <--------- Fetch fact detail from the API --------->
    const fetchFactDetail = async (factId) => {
        const result = await getSingleFactApi(factId);
        if (result?.status === 200) {
            setFactsList((prevSelectedFacts) => {
                const isFactAlreadySelected = prevSelectedFacts.some(
                    (fact) => fact?.factId === factId
                );

                if (isFactAlreadySelected) {
                    return prevSelectedFacts.filter(
                        (fact) => fact?.factId !== factId
                    );
                } else {
                    return [...prevSelectedFacts, result.data];
                }
            });

            setQuizQuestions((prevQuestions) => [...prevQuestions, ...result.data?.questions]);
        }
    };

    const handleFactSelection = async (row) => {

        await fetchFactDetail(row?.original?.id);

    };


    async function handleConfirmation() {
        const date = moment(selectedDate).format('YYYY-MM-DD');
        const body = {
            factId: currentFact?.id,
            date: date
        }
        setIsAssigningFactOfTheDay(true);
        const result = await postFactOfTheDayApi(body);
        if (result?.status === 200) {
            setIsModalOpen(false);
            const assignedFactOfTheDay = {
                [date]: {
                    marked: true,
                    factId: currentFact?.id
                }
            };
            setMarkedDates((prevFactOfTheDays) => ({ ...prevFactOfTheDays, ...assignedFactOfTheDay }));
            // setData(result.data.results);
            // setTotalPages(result.data.totalPage);
            showToast('Fact of the assigned', SUCCESS_VARIANT)
        }
        setIsAssigningFactOfTheDay(false);
    }

    return (
        <div className="p-8 min-h-[90vh] w-[70vw] bg-white rounded-xl">

            {isModalOpen && (
                <CustomModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}>
                    <ViewFact
                        setIsModalOpen={setIsModalOpen}
                        factId={currentFact?.id}
                    />
                </CustomModal>
            )}

            {/* Global Search Filter */}
            <GlobalFilter
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                preGlobalFilteredRows={preGlobalFilteredRows}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
            />

            {/* Data Table */}
            <div className="overflow-x-auto mt-4 pb-4">
                <table {...getTableProps()} className="min-w-full bg-white">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className="px-4 py-2 bg-white text-left text-sm font-bold text-gray-500 tracking-wider border-b border-gray-300 cursor-pointer font-quicksand "
                                    >
                                        <div className='flex items-center'>
                                            {column.render('Header')}
                                            <span className="ml-2">
                                                {!column.disableSortBy && (
                                                    column.isSorted ? (
                                                        column.isSortedDesc ? (
                                                            <Icon
                                                                icon="eva:arrow-down-fill"
                                                                color="gray"
                                                                fontSize={14}
                                                            />
                                                        ) : (
                                                            <Icon
                                                                icon="eva:arrow-up-fill"
                                                                color="gray"
                                                                fontSize={14}
                                                            />
                                                        )
                                                    ) : (
                                                        <Icon
                                                            icon="flowbite:sort-outline"
                                                            color="gray"
                                                            fontSize={14}
                                                        />
                                                    )
                                                )}
                                            </span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {isLoading ? (
                            <tr>
                                <td
                                    colSpan={columns.length} // Spans all columns
                                    className="px-4 py-10 text-sm font-quicksand font-semibold text-gray-700 border-b border-gray-300 text-center"
                                >
                                    <Loader
                                        color={customBlue}
                                        size="2.5rem"
                                        borderWidth='3px'
                                    />
                                </td>
                            </tr>
                        ) :

                            page.length > 0 ? (
                                page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()} className="hover:bg-gray-50">
                                            {row.cells.map((cell) => (
                                                <td
                                                    onClick={(event) => handleClick(event, 'VIEW', row.original)}
                                                    {...cell.getCellProps()}
                                                    className="px-4 py-4 text-sm font-quicksand font-semibold text-gray-700 border-b border-gray-300 cursor-pointer"
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            ))}
                                        </tr>
                                    );
                                })) : (
                                <tr>
                                    <td
                                        colSpan={columns.length}
                                        className='border-b border-gray-300'
                                    >
                                        <NoDataFound
                                            message={'No Fact Found'}
                                        />
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>

            {/* Pagination Controls */}
            <div className="mt-4">
                <Pagination
                    totalPages={totalPages}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                />
            </div>
        </div>
    );
};

export default FactsListForQuizDataTable;
