import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Icon } from '@iconify/react/dist/iconify.js';
import useOutsideClick from '../../hooks/useOutsideClick';
import CustomModal from '../modals/CustomModal';
import ViewFact from '../fact/ViewFact';
import { getFactDayRangeApi } from '../../rest/FactApi';
import Loader from './Loader';
import { customBlue } from '../../constants/color';
import FactsListForFactOfTheDay from '../dataTables/FactsListForFactOfTheDay';

const StreakCalendar = () => {
  const FIXED_START_DATE = '2023-11-23';
  const [isFactDataTableModalOpen, setIsFactDataTableModalOpen] = useState(false);
  const [isViewFactModalOpen, setIsViewFactModalOpen] = useState(false);
  const [currentFactId, setCurrentFactId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(moment().startOf('month'));
  const [isLoading, setIsLoading] = useState(true);
  const [markedDates, setMarkedDates] = useState({});

  // Fetch range of day facts data from the API
  const fetchData = async (firstDate, lastDate) => {
    setIsLoading(true);
    const result = await getFactDayRangeApi(firstDate, lastDate);
    if (result?.status === 200) {
      let totalFactOfTheDays = {};
      result.data.results.forEach((item) => {
        totalFactOfTheDays[item.date] = { marked: true, factId: item.factId };
      });
      setMarkedDates((prevFactOfTheDays) => ({ ...prevFactOfTheDays, ...totalFactOfTheDays }));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const firstDate = currentMonth.clone().startOf('month').format('YYYY-MM-DD');
    const lastDate = currentMonth.clone().endOf('month').format('YYYY-MM-DD');

    fetchData(firstDate, lastDate);
  }, [currentMonth, setCurrentMonth])

  // Helper function to generate the days of the current month
  const generateCalendar = (month) => {
    const startOfMonth = month.clone().startOf('month');
    const endOfMonth = month.clone().endOf('month');
    const startDayOfWeek = startOfMonth.day();
    const days = [];

    // Fill days of the previous month
    for (let i = 0; i < startDayOfWeek; i++) {
      days.push(null);
    }

    // Fill days of the current month
    for (let day = startOfMonth; day.isBefore(endOfMonth) || day.isSame(endOfMonth, 'day'); day.add(1, 'day')) {
      days.push(day.clone());
    }

    return days;
  };

  const calendarDays = generateCalendar(currentMonth);

  // Function to handle marking a date
  const markDate = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    setMarkedDates({
      ...markedDates,
      [formattedDate]: { marked: true },
    });
  };

  const CalendarDay = ({ date, markedDates }) => {
    const { containerRef, menuRef, isMenuOpen, setIsMenuOpen } = useOutsideClick();
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const isMarked = markedDates[formattedDate] && markedDates[formattedDate].marked;
    const factId = markedDates[formattedDate] && markedDates[formattedDate].factId;

    if (!date) {
      return <div className="aspect-square w-full flex items-center justify-center"></div>; // Empty space for non-current month days
    }

    return (
      <div className="w-full flex justify-center items-center aspect-square">
        <div
          key={date.toString()}
          ref={containerRef}
          onClick={() => {
            // !isLoading && setIsMenuOpen(!isMenuOpen)
            if (isLoading) return;

            if (isMarked) {
              setCurrentFactId(factId);
              setIsViewFactModalOpen(true);
              setIsMenuOpen(false);
            } else {
              setIsFactDataTableModalOpen(true);
              setIsMenuOpen(false);
              // setSelectedDate(date.toString());
            }
            setSelectedDate(date.toString());
          }}
          className={`relative h-8 aspect-square flex items-center justify-center rounded-full hover:bg-gray-200 cursor-pointer
            ${isMarked ? 'bg-sky-400 hover:bg-sky-400' : 'bg-white'} 
            ${date.isSame(moment(), 'day') ? 'border-2 border-gray-300' : 'border-gray-300'}`}
        >
          {isMarked ? (
            <div className="flex flex-col items-center">
              <span className="text-white font-quicksand font-semibold text-sm">
                {date.date()}
              </span>
            </div>
          ) : (
            <span className="text-black font-quicksand font-semibold text-sm">
              {date.date()}
            </span>
          )}

          {isMenuOpen && (
            <div
              ref={menuRef}
              className="absolute z-50 rounded-md shadow-[1px_1px_5px_1px_rgba(0,0,0,0.1)] left-0 top-8 overflow-hidden">

              {
                isMarked && (
                  <div
                    onClick={() => {
                      setCurrentFactId(factId)
                      setIsViewFactModalOpen(true);
                      setIsMenuOpen(false);
                      setSelectedDate(date.toString());
                    }}
                    className="w-24 h-8 flex justify-center items-center bg-white cursor-pointer hover:bg-gray-100">
                    View
                  </div>
                )
              }

              {
                !isMarked && (
                  <div
                    onClick={() => {
                      setIsFactDataTableModalOpen(true);
                      setIsMenuOpen(false);
                      setSelectedDate(date.toString());
                    }}
                    className="w-24 h-8 flex justify-center items-center bg-white cursor-pointer hover:bg-gray-100">
                    Set
                  </div>
                )
              }

            </div>
          )}
        </div>
      </div>
    );
  };

  // Functions to navigate between months
  const goToPreviousMonth = () => {
    setCurrentMonth((prev) => prev.clone().subtract(1, 'month'));
  };

  const goToNextMonth = () => {
    setCurrentMonth((prev) => prev.clone().add(1, 'month'));
  };

  return (
    <div className="w-[400px] mt-8 p-6 bg-white rounded-xl">
      {
        isFactDataTableModalOpen && (
          <CustomModal
            width='100%'
            isModalOpen={isFactDataTableModalOpen}
            setIsModalOpen={setIsFactDataTableModalOpen}>
            <FactsListForFactOfTheDay
              showAddNewButton={false}
              selectedDate={selectedDate}
              setMarkedDates={setMarkedDates}
            />
          </CustomModal>
        )
      }

      {
        isViewFactModalOpen && (
          <CustomModal
            isModalOpen={isViewFactModalOpen}
            setIsModalOpen={setIsViewFactModalOpen}>
            <ViewFact
              factId={currentFactId}
            />
          </CustomModal>
        )
      }

      <div className="flex justify-between items-center mb-6">

        <div className='flex items-center'>
          <h2 className="text-lg font-quicksand font-bold text-black">
            {currentMonth.format('MMM YYYY')}
          </h2>

          {
            isLoading && (
              <div className='ml-3'>
                <Loader color={customBlue} size='1.2rem' />
              </div>
            )
          }
        </div>

        <div>
          <button
            className="text-gray-500 hover:text-gray-900"
            onClick={goToPreviousMonth}
          >
            <Icon
              icon='iconamoon:arrow-left-2'
              color='gray'
              fontSize={24}
            />
          </button>
          <button
            className="text-gray-500 hover:text-gray-900"
            onClick={goToNextMonth}
          >
            <Icon
              icon='iconamoon:arrow-right-2'
              color='gray'
              fontSize={24}
            />
          </button>
        </div>
      </div>

      {/* Weekday Headers */}
      <div className="grid grid-cols-7 gap-2 mb-4">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
          <div key={day} className="text-center font-quicksand text-sm font-medium text-gray-400">
            {day}
          </div>
        ))}
      </div>

      {/* Calendar Days */}
      <div className="grid grid-cols-7 gap-2">
        {/* {calendarDays.map((day) => renderDay(day))} */}
        {calendarDays.map((day) => (
          <CalendarDay
            key={day?.toString()}
            date={day}
            markedDates={markedDates}
          />
        ))}
      </div>
    </div>
  );
};

export default StreakCalendar;
